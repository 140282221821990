import { Github } from "@styled-icons/boxicons-logos/Github";

import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin";

//import { FacebookBox as Facebook } from "@styled-icons/remix-fill/FacebookBox";

import { Instagram } from "@styled-icons/boxicons-logos/Instagram";

const Icons = {
  Github,
  Linkedin,
  Instagram,
};

export default Icons;
